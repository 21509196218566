import React from 'react'
import { Link } from 'react-router-dom';

export const Error404 = () => {
  return (
    <>
        <section >
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-6 col-lg-6 col-xl-5">
              <img 
                src=".//assets/error404.jpg"
                className="img-fluid" 
                alt="logo"
              />
            </div>
            <div className='col-md-6 d-flex flex-wrap flex-column justify-content-center align-content-center'>
            <div className='admin-opciones'>
              <h3>Regresa a la Pagina Principal</h3>
              <Link to="/landingpage">
                <button className="btn btn-primary">Regresar</button>
              </Link>
            </div> 
        </div>      
          </div>  
        </div>      
      </section> 
    </>
  )
}
