import React from 'react'
import {  signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Link } from 'react-router-dom';

export const Logout = () => {
    const handleLogout = async () => {
        try {
          await signOut(auth);
          alert('Has cerrado sesión');
        } catch (error) {
          console.error('Error al cerrar sesión:', error.message);
        }
      };
    
      return (
      <>        
          <div className='d-flex flex-wrap flex-column justify-content-center align-content-center'>
              <div className='admin-opciones'>
                <h3>Consola Administracion</h3>
                <Link to="/adminconsola">
                  <button className="btn btn-primary">Regresar</button>
                </Link>
              </div> 
            </div> 
          <div className='m-3'>
          <Link to="/landingpage">
            <button className='btn btn-primary' onClick={handleLogout}>Cerrar sesión</button>
          </Link>
          </div>        
      </>
      );
    };