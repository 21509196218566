import React from 'react'
import { NavLink } from 'react-router-dom';

export const NavBar = () => {
  return (
    <>
        <nav className="nav-container">
        <div className="nav-main">
            <div className="nav-icons">
                <NavLink to="/landingpage" className={({isActive})=> isActive ? "active-nav":""}>
                    <div className="icon-container">
                        <img src="/assets/home-svgrepo-com.svg" alt="Home icon" className="icon"/>
                        <span className="icon-text">Inicio</span>
                    </div>
                </NavLink>
            </div>
            <div className="nav-icons">
                <NavLink to="cursolanding" className={({isActive})=> isActive ? "active-nav":""}>
                    <div className="icon-container">
                        <img src="/assets/courses-svgrepo-com.svg" alt="Courses icon" className="icon"/>
                        <span className="icon-text">Cursos</span>
                    </div>
                </NavLink>
            </div>
            <div className="nav-icons">
                <NavLink to="nosotrospagina" className={({isActive})=> isActive ? "active-nav":""}>
                    <div className="icon-container">
                        <img src="/assets/about-us-svgrepo-com.svg" alt="About icon" className="icon"/>
                        <span className="icon-text">Nosotros</span>
                    </div>
                </NavLink>
            </div>
            <div className="nav-icons">
                <a href="https://www.uxdevschoolblog.online" className="nav-link">
                    <div className="icon-container">
                        <img src="/assets/blog-svgrepo-com.svg" alt="Home icon" className="icon"/>
                        <span className="icon-text">Blog</span>
                    </div>
                </a>
            </div>
            <div className="nav-icons">
                <a href="https://foro.uxdevschool.com" className="nav-link">
                    <div className="icon-container">
                <img src="/assets/question-circle-svgrepo-com.svg" alt="Login icon" className="icon"/>
                <span className="icon-text">Foro</span>
                    </div>
                </a>
            </div>
            <div className="nav-icons">
                <NavLink to="/login" className={({isActive})=> isActive ? "active-nav":""}>
                    <div className="icon-container">
                        <img src="/assets/login-svgrepo-com.svg" alt="Home icon" className="icon"/>
                        <span className="icon-text">Login</span>
                    </div>
                </NavLink>
            </div>
        </div>    
    </nav>
    </>
  )
}
