import React, { useState, useEffect } from 'react';
import { db, collection, getDocs, deleteDoc, doc } from './firebaseConfig'; // Asegúrate de que estas funciones estén exportadas
import { Link } from 'react-router-dom';

export const AdminBlog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollectionRef = collection(db, 'posts');
        const querySnapshot = await getDocs(postsCollectionRef);
        const postsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date ? doc.data().date.toDate() : new Date(), // Convertir el timestamp a Date
        }));

        setPosts(postsList);
      } catch (error) {
        console.error("Error fetching posts: ", error);
      }
    };

    fetchPosts();
  }, []);

  const handleDelete = async (id) => {
    try {
      const postDocRef = doc(db, 'posts', id);
      await deleteDoc(postDocRef);
      setPosts(posts.filter(post => post.id !== id));
    } catch (error) {
      console.error("Error deleting post: ", error);
    }
  };

  return (
    <>
      <div className='d-flex flex-wrap flex-column justify-content-center align-content-center'>
            <div className='admin-opciones'>
              <h3>Consola Administracion</h3>
              <Link to="/adminconsola">
                <button className="btn btn-primary">Regresar</button>
              </Link>
            </div> 
        </div>  
        <div className="container my-4 admin-opciones">
          <h1 className="mb-4">Admin Blog</h1>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Date</th>
                <th scope="col">Author</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map(post => (
                <tr key={post.id}>
                  <td>{post.title}</td>
                  <td>{post.date.toLocaleDateString()} {post.date.toLocaleTimeString()}</td>
                  <td>{post.author}</td>
                  <td>
                    <button 
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(post.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
};

export default AdminBlog;
