import React from 'react'
import { Link } from 'react-router-dom';

export const AdminConsola = () => {
  return (
    <>
      <section className='admin-main-section'>
        <div className='admin-title'><h1>Administracion</h1></div>
        <div className='container-fluid'>
          <div className='d-flex flex-column flex-wrap justify-content-center align-content-center m-3 '>
            <div className='admin-opciones'>
              <h3>Administracion Cursos</h3>
              <Link to="/admincursos">
                <button className="btn btn-primary">Administracion Cursos</button>
              </Link>
            </div>
            <div className='admin-opciones'>
              <h3>Registro de usuarios</h3>
              <Link to="/register">
                <button className="btn btn-primary">Registro de Usuarios</button>
              </Link>
            </div>
            <div className='admin-opciones'>
              <h3>Administracion Blog</h3>
              <Link to="/adminblog">
                <button className="btn btn-primary">Administracion Blog</button>
              </Link>
            </div>
            <div className='admin-opciones'>
              <h3>Comentarios Blog</h3>
              <Link to="/admincomentarios">
                <button className="btn btn-primary">Comentarios</button>
              </Link>
            </div>  
            <div className='admin-opciones'>
              <h3>Logout</h3>
              <Link to="/logout">
                <button className="btn btn-primary">Logout</button>
              </Link>
            </div>    
                      
          </div>
        </div>
      </section>
    </>
  )
}
