import React, { useState } from 'react'
import {  signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Inicio de sesión exitoso:', userCredential); // Imprime información del usuario
      console.log('Usuario autenticado:', userCredential.user); // Información del usuario
      console.log('Intentando redirigir a /adminconsola');
      navigate("/adminconsola"); // Redirige a AdminConsola después del login
      console.log('Redirección a /adminconsola ejecutada'); 
    } catch (error) {
      alert('Error al inicio de sesion')
      console.error('Error al iniciar sesión:', error.message);
    }
  };

  return (
    <>     
      <section >
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img 
                src=".//assets/uxdevschool-logo.png"
                className="img-fluid" 
                alt="logo"
              />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 form1">
              <form onSubmit={handleLogin}>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="email" 
                    id="form3Example3" 
                    className="form-control form-control-lg"
                    placeholder="Correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="form3Example3">Correo Electrónico</label>
                </div>
                <div data-mdb-input-init className="form-outline mb-3">
                  <input
                    type="password" 
                    id="form3Example4" 
                    className="form-control form-control-lg"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <label className="form-label" htmlFor="form3Example4">Contraseña</label>
                </div>  
                <button type="submit">Iniciar sesión</button>
              </form>
            </div>          
          </div>  
        </div>      
      </section> 
    </>  
  );
}