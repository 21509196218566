import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { NavBar } from '../NavBar';
import { LandingPage } from '../LandingPage';
import { Footer } from '../Footer';
import { NosotrosPagina } from '../NosotrosPagina';
//import { Blog } from '../Blog';
import { AdminCursos } from '../AdminCursos';
import { CursoLanding } from '../CursoLanding';  // Asegúrate de que esta importación esté presente
import { Register } from '../Register';
import { Login } from '../Login';
import { ProtectedRoute } from '../ProtectedRoute';
import { AdminConsola } from '../AdminConsola';
import { Logout } from '../Logout';
import { AddPost } from '../AddPost';
import { AdminBlog } from '../AdminBlog';
import { Error404 } from '../Error404';
import { ComentariosPosts } from '../ComentariosPosts';

export const Rutas = () => {
  const [cursos, setCursos] = useState([]);

  const fetchCursos = async () => {
    const querySnapshot = await getDocs(collection(db, 'cursos'));
    const cursosArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCursos(cursosArray);
  };

  useEffect(() => { 
    fetchCursos();
  }, []);
  return (
    <Router>
      <NavBar />
      <section>
        <Routes>
          <Route path='/' element={<Navigate to="/landingpage" />} />
          <Route path='/landingpage' element={<LandingPage />} />
          <Route path='/cursolanding' element={<CursoLanding cursos={cursos} />} />  {/* Asegúrate de que este prop se pase correctamente */}
          <Route path='/nosotrospagina' element={<NosotrosPagina />} />
          {/*<Route path='/blog' element={<Blog />} />*/}
          <Route path='/admincursos' element={<ProtectedRoute element={<AdminCursos fetchCursos={fetchCursos}/>}/>} />
          <Route path='/register' element={<ProtectedRoute element={<Register />} />}/>
          <Route path='/login' element={<Login />} />
          <Route path='/adminconsola' element={<ProtectedRoute element={<AdminConsola/>}/>} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/addpost' element={<AddPost />} />
          <Route path='/adminblog' element={<ProtectedRoute element={<AdminBlog />} />}/>
          <Route path='/admincomentarios' element={<ProtectedRoute element={<ComentariosPosts />} />}/>
          
          
          <Route path='*' element={ <Error404/>}  />       
        
        </Routes>
      </section>
      <Footer />
    </Router>
  );
};
