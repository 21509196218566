import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth'; // Asegúrate de que el hook useAuth esté en la ubicación correcta

export const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { user } = useAuth();

  return user ? Component : <Navigate to="/adminconsola" />;
};


