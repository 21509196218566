import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Hero } from './Hero';
import { Valores } from './Valores';
import { Frase } from './Frase';
import Newsletter from './Newsletter';
import { Testimonios } from './Testimonios';

import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { CursosPagina } from './CursosPagina';


export const LandingPage = () => { 
  const [cursos, setCursos] = useState([]);

  const fetchCursos = async () => {
    const querySnapshot = await getDocs(collection(db, 'cursos'));
    const cursosArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCursos(cursosArray);
  };

  useEffect(() => {
    fetchCursos();
  }, []);

  return (  
    <>
      <Helmet>
      <link rel="canonical" href="https://www.uxdevschool.com/landingpage" />
      </Helmet>
        <Hero/>
        <Valores/>
        <Frase/>
        <CursosPagina limite="4" cursos={cursos}/>
        <Newsletter/>
        <Testimonios/>
    </>
  )
}
export default LandingPage;
