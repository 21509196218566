import React, { useState } from 'react';

export const CursoLanding = ({ cursos = [], limite }) => {
  const [busqueda, setBusqueda] = useState('');
  const [categoria, setCategoria] = useState('Todas');

  // Filtrar los cursos según la búsqueda y la categoría
  const cursosFiltrados = cursos
    .filter(curso => 
      (curso.titulo.toLowerCase().includes(busqueda.toLowerCase()) || busqueda === '') &&
      (categoria === 'Todas' || curso.categoria === categoria)
    )
    .slice(0, limite);
 
  return (
    <section className="main-cursos-container">
      <div className="container-cursos">
        <div className="row">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
            {/* Campo de búsqueda */}
            <input
              type="text"
              className="form-control me-2"
              placeholder="Buscar cursos..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
            />

            {/* Filtro por categoría */}
            <select
              className="form-select"
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
            >
              <option value="Todas">Todas las categorías</option>
              <option value="Diseno web">Diseño web</option>
              <option value="Diseno UX">Diseño UX</option>
              <option value="Marketing Digital">Marketing Digital</option>
            </select>
          </div>
          
          <div className="main-cursos d-flex flex-wrap justify-content-center">
            {cursosFiltrados.length > 0 ? (
              cursosFiltrados.map((curso, index) => (
                <div 
                  key={index} 
                  className="card card-course m-3 col-12 col-md-6 col-lg-4"
                  style={{ maxWidth: '350px', flex: '1 1 300px' }}
                >
                  <img 
                    src={`/assets/${curso.imagen}.png`} 
                    className="card-img-top m-2" 
                    alt={`Imagen Miniatura Curso ${curso.titulo}`} 
                    style={{ objectFit: 'cover', height: '180px' }}
                  />
                  <div className="card-body d-flex flex-column">
                    <h6>{curso.categoria}</h6>
                    <h4 className="card-title">{curso.titulo}</h4>
                    <p className="card-text" style={{ flex: '1' }}>
                      {curso.descripcion.length > 100 
                        ? `${curso.descripcion.substring(0, 100)}...` 
                        : curso.descripcion}
                    </p>
                    <div className="d-flex justify-content-center mt-2">
                      <a href={curso.urlVideo} className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer">Ir al Curso</a>                      
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No hay cursos disponibles.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
