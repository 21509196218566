import React from 'react'


export const NosotrosPagina = () => {

 
  return (
    <>
    <section className="nosotros">
      <div className='row'>
        <div className='col-md-2'></div>
          <div className="container-fluid center-text d-flex justify-content-center align-items-center m-md-3 col-12 col-md-8">
              <div>                            
                  <h2><strong>Sobre Nosotros</strong>  </h2>
                  <p>En UXDev School, nos dedicamos a ofrecer cursos en línea de alta calidad, diseñados especialmente para personas sin experiencia previa en diseño UX, diseño web y marketing digital. Nuestro objetivo es proporcionar una educación accesible y efectiva, que permita a nuestros estudiantes adquirir las habilidades necesarias para implementar estos conocimientos en sus negocios o emprendimientos.</p>
                  <h3>Nuestra Misión</h3>
                  <p>Nuestra misión es empoderar a nuestros estudiantes a través del aprendizaje práctico y la aplicación real de los conocimientos adquiridos. Queremos que cada persona que pase por nuestros cursos se sienta capacitada para crear y avanzar en su camino profesional.</p>
                  <h3>Nuestros Valores</h3>
                  <p>En UXDev School, nos guiamos por tres pilares fundamentales:</p>
                  <ul>
                      <li>Fortaleza: Creemos en la capacidad de cada individuo para aprender y crecer. Nuestros cursos están diseñados para fortalecer las habilidades y la confianza de nuestros estudiantes.</li>
                      <li>Pasión: Nos apasiona lo que hacemos y buscamos transmitir esa pasión a nuestros estudiantes. Creemos que el aprendizaje debe ser una experiencia emocionante y enriquecedora.</li>
                      <li>Conexión: Fomentamos una comunidad de aprendizaje donde los estudiantes pueden conectarse entre sí, compartir ideas y colaborar en proyectos. La conexión humana es clave para el aprendizaje y el crecimiento.</li>
                  </ul>
                  <h3>Nuestro Enfoque</h3>
                  <p>Nuestro enfoque es simple: educación de calidad para todos. Nos esforzamos por crear contenido que sea accesible, comprensible y práctico. Utilizamos métodos ágiles y una estrecha colaboración con nuestros estudiantes para asegurarnos de que reciban el apoyo necesario en su viaje de aprendizaje.

                                  Únete a nosotros en UXDev School y comienza tu viaje hacia un futuro lleno de posibilidades. Aprende, crea, avanza.</p>
              </div>
          </div>
        
      </div>                   
    </section>
    
    </>
  );
};

export default NosotrosPagina;