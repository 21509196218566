import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'; // Importar autenticación
import { getFirestore, collection, addDoc, getDocs, doc, deleteDoc } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCysFA5Wrs-_vcxhgl4RLRWZ1H54DrbLfY",
  authDomain: "cursos-uxdevschool.firebaseapp.com",
  projectId: "cursos-uxdevschool",
  storageBucket: "cursos-uxdevschool.appspot.com",
  messagingSenderId: "228910472781",
  appId: "1:228910472781:web:11ad5dbec84df2e8044604"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export {auth, db, collection, addDoc, getDocs, doc,deleteDoc };