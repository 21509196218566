import React from 'react'

export const Testimonios = () => {
  return (
    <>
        <section className="testimonio-container ">
        <div className="d-flex flex-column container-fluid justify-content-center align-items-center">
            <div className="testimonio">
                <h2>Testimonios</h2>
            </div>
            <div className="row">
                <div className="d-flex justify-content-center align-items-center flex-column flex-md-row container-fluid ">
                    <img className="col-12 col-md-3" src=".//assets/ReviewFinal1.png" alt="Testimonio1"/>
                    <img className="col-12 col-md-3" src=".//assets/ReviewFinal2.png" alt="Testimonio2"/>
                    <img className="col-12 col-md-3" src=".//assets/ReviewFinal3 (1).png" alt="Testimonio3"/>
                    <img className="col-12 col-md-3" src=".//assets/ReviewFinal3.png" alt="Testimonio4"/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
