import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const API_KEY = '66b89f0a6cd3a4a929491e63-0dArW5QUtBhHVwMi5C50p1QhGfbeRL6uQrpl0rheQ9jOSQdcKN'; // Reemplaza con tu API Key de Omnisend
  const API_URL = 'https://api.omnisend.com/v5/contacts'; // URL del endpoint de Omnisend para contactos

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post(API_URL, {
        identifiers: [
          {
            type: 'email',
            channels: {
              email: {
                status: 'subscribed',
                statusDate: new Date().toISOString(), // Usa la fecha y hora actuales
              },
            },
            id: email,
          },
        ],
      }, {
        headers: {
          'X-API-KEY': API_KEY, // Usa X-API-KEY para autenticación
          'accept': 'application/json',
          'content-type': 'application/json',
        },
      });

      if (response.status === 200) {
        setMessage('¡Gracias por suscribirte!');
        setEmail('');
      } else {
        setMessage('Hubo un error al suscribirte. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error(error); // Log del error para depuración
      setMessage('Hubo un error al suscribirte. Por favor, inténtalo de nuevo.');
    }
  };

  return (
  <> 
    <Helmet>
      <link rel="canonical" href="https://www.uxdevschool.com/landingpage" />
    </Helmet>
    <section className="main-newsletter">
      <div className="container newsletter-container">
        <div className="row">
          <div className="newsletter-container">
            <div className="mx-auto">
              <div className="d-flex justify-content-center align-items-center gap-3 flex-column flex-md-row">
                <img
                  className="mt-3 mb-4 newsletter-img col-12 col-md-4 img-fluid"
                  src="./assets/news-8283285_640.png"
                  width="150px"
                  alt="newsletter"
                />
                <p className="col-12 col-md-4 text-center mt-3 mb-4">
                  ¡No te pierdas lo último en diseño y desarrollo! Suscríbete a nuestro newsletter y recibe inspiración, tips exclusivos y las novedades más emocionantes directamente en tu inbox. ¡Únete a nuestra comunidad creativa hoy!
                </p>
                <form className="col-12 col-md-4 mb-4" onSubmit={handleSubmit}>
                  <div className="input-group input-newsletter flex-nowrap">
                    <span className="input-group-text" id="addon-wrapping">@</span>
                    <input
                      type="email"
                      value={email}
                      id="email"
                      name="EMAIL"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="form-control"
                      placeholder="Correo Electronico"
                      aria-label="Correo Electronico"
                      aria-describedby="addon-wrapping"
                    />
                    <button type="submit" className="btn btn-primary">Suscríbete!</button>
                  </div>
                </form>
                {message && <p>{message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </> 
  );
};

export default Newsletter;
