import React, {useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

export const Valores = () => {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
    <Helmet>
      <link rel="canonical" href="https://www.uxdevschool.com/landingpage" />
    </Helmet>
        <section className="valores-container">
            <div className="valores-main container-fluid my-4">
                <div className="row">
                    <div className="flex-nowrap col-12 d-flex flex-column flex-lg-row gap-4 justify-content-evenly align-items-center my-4">
                        
                        <div className="col-10 col-md-5 col-lg-3 tarjeta-main tarjeta">
                            <div className="p-2 tarjeta-main-div">
                                <h4>Porque Nosotros?</h4>
                                <p>En UXDev School, nuestra experiencia nos avala. Hemos diseñado un enfoque educativo que empodera, inspira pasión y fomenta conexiones auténticas. Con años de experiencia en el mundo digital, trasladamos nuestro conocimiento a cursos accesibles y de alta calidad en marketing digital, programación y diseño UX. </p>
                            </div>
                        </div>
                        
                        <div className="col-10 col-md-6 col-lg-2 tarjeta-secundaria tarjeta grow-card">
                            <div className="p-3">
                                <h4>Fortaleza</h4><br/>
                                <p>Creemos en el poder del conocimiento para transformar vidas, impulsando a nuestros estudiantes a alcanzar sus sueños y crear su propio futuro. </p>
                            </div>
                        </div>
                        
                        <div className="col-10 col-md-6 col-lg-2 tarjeta-secundaria tarjeta">
                            <div className="p-3">
                                <h4>Pasión</h4>
                                <p>Fomentamos una profunda pasión por el aprendizaje y la innovación, animando a nuestros estudiantes a explorar y desafiar los límites del mundo digital. </p>
                            </div>
                        </div>
                        
                        <div className="col-10 col-md-6 col-lg-2 tarjeta-secundaria tarjeta">
                            <div className="p-3">
                                <h4>Conexión</h4>
                                <p>Valoramos la conexión auténtica y el apoyo dentro de la comunidad, creando un entorno donde los estudiantes pueden colaborar, inspirarse y crecer juntos. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
    </>
  )
}
