import React, { useState } from 'react'
import {  createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Link } from 'react-router-dom';

export const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const handleRegister = async (e) => {
      e.preventDefault();
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        alert('Usuario registrado con éxito');
      } catch (error) {
        console.error('Error al registrar:', error.message);
      }
    };
  
    return (
      <div>
        <div className='d-flex flex-wrap flex-column justify-content-center align-content-center'>
            <div className='admin-opciones'>
              <h3>Consola Administracion</h3>
              <Link to="/adminconsola">
                <button className="btn btn-primary">Regresar</button>
              </Link>
            </div> 
          <div className='admin-opciones'>  
            <h2>Registro</h2>
            <form onSubmit={handleRegister} className='m-3'>
              <div>
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="form-control"
                />
                <label className="form-label">Correo Electronico</label>
                <input
                  type="password"
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="form-control"
                />
                <label className="form-label">Contraseña</label>
              </div>
                <button className="btn btn-primary" type="submit">Registrar</button>
            </form>
          </div>  
      </div>
      </div>
    );
  };