import React from 'react'
import { Helmet } from 'react-helmet';

export const Hero = () => {
  return (
    <>
    <Helmet>
      <link rel="canonical" href="https://www.uxdevschool.com/landingpage" />
    </Helmet>
    <section className="hero-container overflow-hidden ">
        <div className="row">
                <div className="hero-main d-flex mt-2 flex-wrap justify-content-between">
                    <div className="order-2 order-md-1 col-12 col-md-7 logo d-flex justify-content-between align-items-center">
                        <img src=".//assets/uxdevschool-logo.png" className="img-fluid" width="650px" height="auto" alt="uxdevschool-logo"/>
                    </div>                    
                    <div className="order-1 order-md-2 col-12 col-md-5 hero-text d-flex justify-content-between align-items-center p-4">
                        <img src=".//assets/uxdevslogan.png" className="img-fluid" width="750px" height="auto" alt="uxdevschool-slogan"/>
                    </div>
                </div>                
        </div>
    </section>
    </>
  )
}
