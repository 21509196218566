import React, { useState, useEffect } from 'react';
import { collection, addDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';

export const AdminCursos = () => {
  const [nuevoCurso, setNuevoCurso] = useState({
    categoria: '',
    titulo: '',
    descripcion: '',
    imagen: '',
    urlVideo: ''
  });
  const [cursos, setCursos] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchCursos();
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNuevoCurso({
      ...nuevoCurso,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user && nuevoCurso.titulo && nuevoCurso.categoria && nuevoCurso.descripcion && nuevoCurso.imagen && nuevoCurso.urlVideo) {
      try {
        await addDoc(collection(db, 'cursos'), nuevoCurso);
        setNuevoCurso({
          categoria: '',
          titulo: '',
          descripcion: '',
          imagen: '',
          urlVideo: ''
        });
        fetchCursos();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const fetchCursos = async () => {
    try {
      const cursosSnapshot = await getDocs(collection(db, 'cursos'));
      const cursosList = cursosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCursos(cursosList);
    } catch (e) {
      console.error("Error fetching documents: ", e);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (user) {
        await deleteDoc(doc(db, 'cursos', id));
        fetchCursos(); // Refrescar los cursos
      }
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  };

  return (
    <section className="m-2 p-2 adminCursos-section">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className='d-flex flex-wrap flex-column justify-content-center align-content-center'>
            <div className='admin-opciones'>
              <h3>Consola Administracion</h3>
              <Link to="/adminconsola">
                <button className="btn btn-primary">Regresar</button>
              </Link>
            </div>
          </div>
          <div className="col-md-8 col-lg-6 col-xl-5 admin-cursos m-3">
            <h2>Administrar Cursos</h2>
            <table className="table table-bordered">
              <thead>
                <tr className='table-primary'>
                  <th>Título</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cursos.map((curso) => (
                  <tr key={curso.id}>
                    <td>{curso.titulo}</td>
                    <td>
                      <a
                        href={curso.urlVideo}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary btn-sm me-2"
                      >
                        Ver Video
                      </a>
                      <button
                        onClick={() => handleDelete(curso.id)}
                        disabled={!user}
                        className="btn btn-danger btn-sm"
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-9 col-lg-6 col-xl-4 offset-xl-1 agregar-curso">
            <h2>Agregar Cursos</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <select
                  name="categoria"
                  value={nuevoCurso.categoria}
                  onChange={handleChange}
                  required
                  className="form-select"
                >
                  <option value="" disabled>Selecciona una categoría</option>
                  <option value="Diseno web">Diseño web</option>
                  <option value="Diseno UX">Diseño UX</option>
                  <option value="Marketing Digital">Marketing Digital</option>
                </select>
                <label className="form-label">Categoría</label>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="titulo"
                  placeholder="Título"
                  value={nuevoCurso.titulo}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label className="form-label">Título</label>
              </div>
              <div className="mb-3">
                <textarea
                  name="descripcion"
                  placeholder="Descripción"
                  value={nuevoCurso.descripcion}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label className="form-label">Descripción</label>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="imagen"
                  placeholder="Nombre de la Imagen (sin espacios)"
                  value={nuevoCurso.imagen}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label className="form-label">Imagen</label>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  name="urlVideo"
                  placeholder="URL del Video"
                  value={nuevoCurso.urlVideo}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
                <label className="form-label">URL del Video</label>
              </div>
              <button className="btn btn-primary" type="submit" disabled={!user}>Agregar Curso</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminCursos;
