import React, { useState, useEffect } from 'react';
import { db, collection, getDocs, deleteDoc, doc, collection as collectionRef, getDocs as getDocsSubCollection } from './firebaseConfig';
import { Link } from 'react-router-dom';

export const ComentariosPosts = () => {
    const [posts, setPosts] = useState([]);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [comments, setComments] = useState({});
  
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const postsCollectionRef = collection(db, 'posts');
          const querySnapshot = await getDocs(postsCollectionRef);
          const postsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date ? doc.data().date.toDate() : new Date(),
          }));
  
          setPosts(postsList);
        } catch (error) {
          console.error("Error fetching posts: ", error);
        }
      };
  
      fetchPosts();
    }, []);
  
    useEffect(() => {
      if (selectedPostId) {
        const fetchComments = async () => {
          try {
            const commentsCollectionRef = collectionRef(db, 'posts', selectedPostId, 'comments');
            const querySnapshot = await getDocsSubCollection(commentsCollectionRef);
            const commentsList = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              date: doc.data().date ? doc.data().date.toDate() : new Date(),
            }));
  
            setComments(prevComments => ({ ...prevComments, [selectedPostId]: commentsList }));
          } catch (error) {
            console.error("Error fetching comments: ", error);
          }
        };
  
        fetchComments();
      }
    }, [selectedPostId]);
  
    const handleDeleteComment = async (postId, commentId) => {
      try {
        await deleteDoc(doc(db, 'posts', postId, 'comments', commentId));
        setComments(prevComments => ({
          ...prevComments,
          [postId]: prevComments[postId].filter(comment => comment.id !== commentId)
        }));
      } catch (error) {
        console.error("Error deleting comment: ", error);
      }
    };
  
    return (
      <div className="container my-4">
        <div className='admin-opciones'>
              <h3>Consola Administracion</h3>
              <Link to="/adminconsola">
                <button className="btn btn-primary">Regresar</button>
              </Link>
            </div> 
        <h1 className="mb-4">Posts</h1>
        <div className="list-group mb-4">
          {posts.map(post => (
            <button
              key={post.id}
              className="list-group-item list-group-item-action"
              onClick={() => setSelectedPostId(post.id)}
            >
              {post.title}
            </button>
          ))}
        </div>
  
        {selectedPostId && (
          <div>
            <h2>Comentarios de Post</h2>
            <ul className="list-unstyled">
              {(comments[selectedPostId] || []).map(comment => (
                <li key={comment.id} className="mb-2 border p-2 rounded">
                  <p>{comment.text}</p>
                  <footer className="blockquote-footer">
                    {comment.author} - {comment.date.toLocaleDateString()} {comment.date.toLocaleTimeString()}
                  </footer>
                  <button
                    className="btn btn-danger btn-sm mt-2"
                    onClick={() => handleDeleteComment(selectedPostId, comment.id)}
                  >
                    Borrar Comentario
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };
  
  export default ComentariosPosts;
