import React, { useState } from 'react';
import { db, collection, addDoc } from './firebaseConfig';
import { Timestamp } from 'firebase/firestore';

export const AddPost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [category, setCategory] = useState(''); // Agregar estado para la categoría
  const [url, setUrl] = useState(''); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const postsCollectionRef = collection(db, 'posts');
      await addDoc(postsCollectionRef, {
        title,
        content,
        author,
        category, 
        url,// Incluir categoría en los datos
        date: Timestamp.fromDate(new Date()),
      });

      setTitle('');
      setContent('');
      setAuthor('');
      setCategory('');
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="container my-4">
      <h1 className="mb-4">Agregar Post</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="formTitle">Titulo</label>
          <input
            type="text"
            className="form-control"
            id="formTitle"
            placeholder="Titulo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="formContent">Contenido</label>
          <textarea
            className="form-control"
            id="formContent"
            rows="5"
            placeholder="Contenido"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="formAuthor">Autor</label>
          <input
            type="text"
            className="form-control"
            id="formAuthor"
            placeholder="Autor"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="formUrl">URL de Leer Más</label>
          <input
            type="text"
            className="form-control"
            id="formUrl"
            placeholder="Ingresa URL completa Ej. https://www.uxdevschool.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="formCategory">Categorias</label>
          <select
            className="form-control"
            id="formCategory"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Selecciona Categorias</option>
            <option value="Diseño UX">Diseño UX</option>
            <option value="Diseño Web">Diseño Web</option>
            <option value="Marketing Digital">Marketing Digital</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Agregar Post
        </button>
      </form>
    </div>
  );
};

export default AddPost;
