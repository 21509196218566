
import './App.css';

import { Rutas } from './components/Router/Rutas';



function App() {
  return (
    <div className="App">
      <Rutas/>
    </div>
  );
}

export default App;
