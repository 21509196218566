import React from 'react'
import { Helmet } from 'react-helmet';


export const Frase = () => {
  return (
    <>
    <Helmet>
      <link rel="canonical" href="https://www.uxdevschool.com/landingpage" />
    </Helmet>
        <section className="d-flex align-items-center position-relative vista">
            <video playsInline loop autoPlay muted autobuffer="true" id="myVideo" className="video-background">
                <source src=".//assets/vecteezy_programmer-and-developer-coding-software-application_35654882.mp4" type="video/mp4"/>
            </video>
            <div className="container position-absolute top-50 start-50 translate-middle">
                <img className="img-fluid" src=".//assets/Frase1-UX.png" alt="Frase de Johann Wolfgang Von Goethe"/>
            </div>
    </section>
    </>
  )
}
